import React from "react";
import UseData from "../../Hooks/UseData";
import Footer from "../../Share/Footer";
import HomeCard from "../../Share/HomeCard";
import PageTitle from "../../Share/PageTitle";
import "../../Share/Style.css";
import AboutCard from "./AboutCard";

const AboutTwo = () => {
  const { local, experienceArray } = UseData();

  return (
    <section>
      <PageTitle title="Yo"></PageTitle>
      {/* End pagetitle */}

      <div className=" lg:rounded-2xl bg-white dark:bg-[#111111]">
        <div data-aos="fade">
          <div className=" pt-12 md:py-12 px-2 sm:px-5 md:px-10 lg:px-14 ">
            {/* About page title */}
            <h2 className="after-effect after:left-52">Sobre mí</h2>
            <div className="lg:hidden">
              {/* Sidebar personal information for mobile devices */}
              <HomeCard />
            </div>
            <div className="lg:grid grid-cols-12 md:gap-10 pt-4 md:pt-[30px] items-center hidden ">
              <div className="col-span-12 space-y-2.5">
                <div className="lg:mr-16">
                  <p className="text-gray-lite dark:text-color-910 leading-7 text-justify">
                  Developer Junior, con experiencia laboral en diferentes sectores. Me considero una persona creativa que le encantan los grandes retos y la innovación.
                  Los proyectos en equipo me apasionan, con grandes ganas de aprendizaje y colaboración en el trabajo.
                      
                  </p>
                  <p className="text-gray-lite leading-7 mt-2.5 dark:text-color-910 text-justify">
                  De forma laboral tengo experiencia en el sector de la infomatica con el mantenimiento de equipos y conexiones, el uso de ERP, comercio, marketing, bases de datos,
                  edición de contenido y servicio al cliente. Como experiencia prelaboral cuento con proyectos de innovación y trabajos prelaborales en fotografía.
                  Conocimientos en Big data con estudios realizados.
                  </p>
                </div>
                <div></div>
              </div>
            </div>
          </div>
          {/* End about descriptions */}

          <div className="  pb-12 px-2 sm:px-5 md:px-10 lg:px-14 ">
            <h3 className="text-[35px] dark:text-white font-medium pb-5">
              
            </h3>
            <div className="grid gap-8 grid-cols-1 md:grid-cols-2 xl:grid-cols-2 ">
              {/* Experience information  */}

              {experienceArray.map((item) => (
                <AboutCard key={Math.random()} item={item} local={local} />
              ))}
            </div>
          </div>
          {/* service provide end */}
          {/* Common Footer call here */}
          <Footer bg={"#FFFF"} />
        </div>
      </div>
    </section>
  );
};

export default AboutTwo;
